import { memo, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import ReCAPTCHA from "react-google-recaptcha";
import SignatureCanvas from "react-signature-canvas";
import clsx from "clsx";

const Email = memo(({ value }) => {
  const [contact, domain] = value.split("@");

  const onClick = () => {
    if (typeof window !== "undefined") {
      window.location.href = `mailto:${value}`;
    }
  };

  return (
    <span
      {...{ onClick }}
      className="email"
      data-contact={contact.split("").reverse().join("")}
      data-domain={domain.split("").reverse().join("")}
    />
  );
});

Email.displayName = "Email";

const Phone = memo(({ value }) => {
  const phoneNumber = value.match(/.{2}/g);

  const onClick = () => {
    if (typeof window !== "undefined") {
      window.location.href = `tel:${value}`;
    }
  };

  return (
    <span
      {...{ onClick }}
      className="phone"
      data-first-part={phoneNumber[0].split("").reverse().join("")}
      data-second-part={phoneNumber[1].split("").reverse().join("")}
      data-third-part={phoneNumber[2].split("").reverse().join("")}
      data-fourth-part={phoneNumber[3].split("").reverse().join("")}
      data-fifth-part={phoneNumber[4].split("").reverse().join("")}
    />
  );
});

Phone.displayName = "Phone";

const Checkbox = memo(({ label, errored, ...props }) => (
  <div
    className={clsx("checkbox-container", {
      "checkbox-error": !!errored,
    })}
  >
    <label className="checkbox-label">
      <input {...props} type="checkbox" className="checkbox-input" />
      <div className="checkbox-box" />
      <p className="checkbox-text">{label}</p>
    </label>
  </div>
));

Checkbox.displayName = "Checkbox";

const App = () => {
  const [values, setValues] = useState({
    company: "",
    siret: "",
    full_name: "",
    function: "",
    email: "",
    phone: "",
    signature: "",
    recaptcha: "",
    options: [],
    tebdp_total: "",
    tebdp_location: "",
    bimod_total: "",
    bimod_location: "",
    cobim_total: "",
    cobim_location: "",
    rules_accepted: false,
    success: false,
  });
  const [errors, setErrors] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const date = useMemo(() => format(new Date(), "dd/MM/yyyy"), []);
  const signatureRef = useRef(null);
  const recaptchaRef = useRef(null);

  const onCheck = (event, value) => {
    const newValue = [...values.options];

    if (event.target.checked) {
      newValue.push(value);

      return setValues((rest) => ({ ...rest, options: newValue }));
    } else {
      const index = newValue.indexOf(value);

      if (index !== -1) {
        newValue.splice(index, 1);
      }

      return setValues((rest) => ({ ...rest, options: newValue }));
    }
  };

  const onChange = (key, value) => {
    return setValues((rest) => ({ ...rest, [key]: value }));
  };

  const onSign = () => {
    const signature = signatureRef?.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png");

    return onChange("signature", signature);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (values?.success) {
      setValues((rest) => ({ ...rest, success: false }));
    }

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/recruitment/epec`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then(({ status, message, data }) => {
        if (status === 200) {
          signatureRef.current.clear();

          setValues({
            company: "",
            siret: "",
            full_name: "",
            function: "",
            email: "",
            phone: "",
            signature: "",
            recaptcha: "",
            options: [],
            tebdp_total: "",
            tebdp_location: "",
            bimod_total: "",
            bimod_location: "",
            cobim_total: "",
            cobim_location: "",
            rules_accepted: false,
            success: true,
          });

          setErrors({});
        } else {
          setErrors(data);
        }
      })
      .finally(() => {
        recaptchaRef.current.reset();
        setIsProcessing(false);
      });
  };

  return (
    <main className="container">
      <div className="image-container" />
      <h1 className="title">Manifestation d’intérêt</h1>
      <form noValidate onSubmit={onSubmit} className="form">
        <p className="description">
          Afin de lier obligations d’insertion et opportunités de développement,
          l'organisme de formation <b>ARINFO</b> et l'<b>EPEC</b> renouvellent
          leur partenariat pour vous accompagner dans vos recrutements à venir
          en <b>alternance</b>. Les 3 titres professionnels proposés sont
          inscrits au RNCP, et certifiés par le{" "}
          <b>Ministère du Travail et de l’Emploi</b>. En amont, une{" "}
          <a
            href="https://www.francetravail.fr/employeur/solution-recrutement/2024/poei-une-formation-sur-mesure-av.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            POE
          </a>{" "}
          (préparation opérationnelle à l’emploi) est réalisée, avec le soutien
          de <b>France Travail</b>. En appui de l’EPEC, ces actions de formation
          ont la capacité de répondre à la{" "}
          <a
            href="https://www.epec.paris/pole-clauses-sociales-de-paris"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b>clause d’insertion</b>.
          </a>
          L’éligibilité du public sera validée par l’EPEC de façon individuelle.
          Les formations auront lieu à «{" "}
          <b>ARINFO Paris – rue Roger Bacon 75017</b> ». Nous consulter pour nos{" "}
          <a
            href="https://www.arinfo.fr/contactez-nous"
            target="_blank"
            rel="noopener noreferrer"
          >
            autres campus
          </a>
          .
        </p>
        <p className="description">
          Le parcours de la POE se déroulera du :
          <ul className="list">
            <li>
              8 septembre au 3 octobre 2025 (140h)
              <p className="description description-secondary">
                Compétences visées : remise à niveau en techniques bâtiment,
                découverte des logiciels CAO/DAO (AutoCAD, Revit…), travail sur
                le savoir-être, veille technologique architecture, valorisation
                de ses compétences en bâtiment.
              </p>
            </li>
          </ul>
        </p>
        <p className="description">
          Les titres professionnels, d’une durée unitaire de 12 mois, à
          envisager en poursuite de parcours :
        </p>
        <div className="form-field">
          <Checkbox
            label={
              <>
                <a
                  href="https://www.arinfo.fr/formations/metiers-du-batiment/technicien-etudes-batiment-dessin-projet"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>TEBDP</b>
                </a>{" "}
                (niveau <b>4</b> équiv <b>Bac</b>. Technicien d'études du
                bâtiment en dessin de projet)
              </>
            }
            name="tebdp"
            checked={values.options.includes("tebdp")}
            onChange={(event) => onCheck(event, "tebdp")}
            disabled={isProcessing}
          />
          {values.options.includes("tebdp") && (
            <div className="form-row">
              <div className="form-field">
                <label className="form-label">
                  Nombre de recrutements envisagés
                </label>
                <input
                  name="tebdp_total"
                  value={values?.tebdp_total}
                  onChange={(event) => {
                    onChange(
                      "tebdp_total",
                      !event.target.value.startsWith("0")
                        ? event.target.value.replace(/[^0-9]/g, "")
                        : ""
                    );
                  }}
                  disabled={isProcessing}
                  className={clsx("form-input", { error: errors?.tebdp_total })}
                />
                {errors?.tebdp_total && (
                  <small className="form-error">{errors?.tebdp_total}</small>
                )}
              </div>
              <div className="form-field">
                <label className="form-label">
                  Ville où se situe l’entreprise d’accueil
                </label>
                <input
                  name="tebdp_location"
                  value={values?.tebdp_location}
                  onChange={(event) => {
                    onChange("tebdp_location", event.target.value);
                  }}
                  disabled={isProcessing}
                  className={clsx("form-input", {
                    error: errors?.tebdp_location,
                  })}
                />
                {errors?.tebdp_location && (
                  <small className="form-error">{errors?.tebdp_location}</small>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="form-field">
          <Checkbox
            label={
              <>
                <a
                  href="https://www.arinfo.fr/formations/metiers-du-batiment/bim_modeleur"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>BIMOD</b>
                </a>{" "}
                (niveau <b>5</b> équiv <b>Bac+2</b>. BIM modeleur du bâtiment)
              </>
            }
            name="bimod"
            checked={values.options.includes("bimod")}
            onChange={(event) => onCheck(event, "bimod")}
            disabled={isProcessing}
          />
          {values.options.includes("bimod") && (
            <div className="form-row">
              <div className="form-field">
                <label className="form-label">
                  Nombre de recrutements envisagés
                </label>
                <input
                  name="bimod_total"
                  value={values?.bimod_total}
                  onChange={(event) => {
                    onChange(
                      "bimod_total",
                      !event.target.value.startsWith("0")
                        ? event.target.value.replace(/[^0-9]/g, "")
                        : ""
                    );
                  }}
                  disabled={isProcessing}
                  className={clsx("form-input", { error: errors?.bimod_total })}
                />
                {errors?.bimod_total && (
                  <small className="form-error">{errors?.bimod_total}</small>
                )}
              </div>
              <div className="form-field">
                <label className="form-label">
                  Ville où se situe l’entreprise d’accueil
                </label>
                <input
                  name="bimod_location"
                  value={values?.bimod_location}
                  onChange={(event) => {
                    onChange("bimod_location", event.target.value);
                  }}
                  disabled={isProcessing}
                  className={clsx("form-input", {
                    error: errors?.bimod_location,
                  })}
                />
                {errors?.bimod_location && (
                  <small className="form-error">{errors?.bimod_location}</small>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="form-field">
          <Checkbox
            label={
              <>
                <a
                  href="https://www.arinfo.fr/formations/metiers-du-batiment/coordinateur_bim_du_batiment"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>COBIM</b>
                </a>{" "}
                (niveau <b>6</b> équiv <b>Bac+3/4</b>. Coordinateur BIM du
                bâtiment)
              </>
            }
            name="cobim"
            checked={values.options.includes("cobim")}
            onChange={(event) => onCheck(event, "cobim")}
            disabled={isProcessing}
          />
          {values.options.includes("cobim") && (
            <div className="form-row">
              <div className="form-field">
                <label className="form-label">
                  Nombre de recrutements envisagés
                </label>
                <input
                  name="cobim_total"
                  value={values?.cobim_total}
                  onChange={(event) => {
                    onChange(
                      "cobim_total",
                      !event.target.value.startsWith("0")
                        ? event.target.value.replace(/[^0-9]/g, "")
                        : ""
                    );
                  }}
                  disabled={isProcessing}
                  className={clsx("form-input", { error: errors?.cobim_total })}
                />
                {errors?.cobim_total && (
                  <small className="form-error">{errors?.cobim_total}</small>
                )}
              </div>
              <div className="form-field">
                <label className="form-label">
                  Ville où se situe l’entreprise d’accueil
                </label>
                <input
                  name="cobim_location"
                  value={values?.cobim_location}
                  onChange={(event) => {
                    onChange("cobim_location", event.target.value);
                  }}
                  disabled={isProcessing}
                  className={clsx("form-input", {
                    error: errors?.cobim_location,
                  })}
                />
                {errors?.cobim_location && (
                  <small className="form-error">{errors?.cobim_location}</small>
                )}
              </div>
            </div>
          )}
        </div>
        <p className="description description-secondary">
          Les coûts pédagogiques sont intégralement pris en charge par l’OPCO,
          sans reste à charge, ni pour l’entreprise, ni pour le candidat. Les
          parcours sont également éligibles aux aides de l’État.
        </p>
        <p className="description">
          Les ressources (PDF) :
          <ul className="list">
            <li>Planning POE 140h (sur demande)</li>
            <li>
              Programme{" "}
              <a
                href="https://api.arinfo.fr/download/arinfo/1709724285494-ARINFO_TEBDP.pdf"
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                TEBDP
              </a>{" "}
              /{" "}
              <a
                href="https://api.arinfo.fr/download/arinfo/1677836373389-BIMOD.pdf"
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                BIMOD
              </a>{" "}
              /{" "}
              <a
                href="https://api.arinfo.fr/download/arinfo/1736779270272-1677836360641-COBIM.pdf"
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                COBIM
              </a>
            </li>
            <li>
              Planning{" "}
              <a
                href="/fichiers/DIS_TEBDP-ALT.pdf"
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                TEBDP
              </a>{" "}
              /{" "}
              <a
                href="/fichiers/DIS_BIMOD-ALT.pdf"
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                BIMOD
              </a>{" "}
              /{" "}
              <a
                href="/fichiers/DIS_COBIM-ALT.pdf"
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                COBIM
              </a>
            </li>
            <li>
              POE : présentation et programme sur la{" "}
              <a
                href="https://www.arinfo.fr/formations/metiers-du-batiment/poe-dessinateur-cao-epec-paris"
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                page dédiée
              </a>
            </li>
          </ul>
        </p>
        <p className="description">
          Interlocuteurs :
          <ul className="list">
            <li>
              <b>EPEC</b> : FAFOURNOUX Simon -{" "}
              <Email value="simon.fafournoux@epec.paris" /> /{" "}
              <Phone value="0184830716" />
            </li>
            <li>
              <b>ARINFO</b> : MAGNALDI Alexia - chargée relations entreprises -{" "}
              <Email value="amagnaldi@arinfo.fr" /> /{" "}
              <Phone value="0244238215" />{" "}
            </li>
            <li>
              <b>ARINFO</b> : EPAUD Brian - directeur CFA -{" "}
              <Email value="bepaud@arinfo.fr" />
            </li>
          </ul>
        </p>
        <div className="form-row">
          <div className="form-field">
            <label className="form-label">Nom entreprise :</label>
            <input
              name="company"
              value={values.company}
              onChange={(event) => onChange("company", event.target.value)}
              disabled={isProcessing}
              className={clsx("form-input", { error: errors?.company })}
            />
            {errors?.company && (
              <small className="form-error">{errors?.company}</small>
            )}
          </div>
          <div className="form-field">
            <label className="form-label">N° SIRET :</label>
            <input
              name="siret"
              value={values.siret}
              onChange={(event) => onChange("siret", event.target.value)}
              disabled={isProcessing}
              className={clsx("form-input", { error: errors?.siret })}
            />
            {errors?.siret && (
              <small className="form-error">{errors?.siret}</small>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-field">
            <label className="form-label">Nom et prénom du signataire :</label>
            <input
              name="full_name"
              value={values.full_name}
              onChange={(event) => onChange("full_name", event.target.value)}
              disabled={isProcessing}
              className={clsx("form-input", { error: errors?.full_name })}
            />
            {errors?.full_name && (
              <small className="form-error">{errors?.full_name}</small>
            )}
          </div>
          <div className="form-field">
            <label className="form-label">Fonction du signataire :</label>
            <input
              name="function"
              value={values.function}
              onChange={(event) => onChange("function", event.target.value)}
              disabled={isProcessing}
              className={clsx("form-input", { error: errors?.function })}
            />
            {errors?.function && (
              <small className="form-error">{errors?.function}</small>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-field">
            <label className="form-label">Email du signataire :</label>
            <input
              name="email"
              value={values.email}
              onChange={(event) => onChange("email", event.target.value)}
              disabled={isProcessing}
              className={clsx("form-input", { error: errors?.email })}
            />
            {errors?.email && (
              <small className="form-error">{errors?.email}</small>
            )}
          </div>
          <div className="form-field">
            <label className="form-label">Téléphone du signataire :</label>
            <input
              name="function"
              value={values.phone}
              onChange={(event) => onChange("phone", event.target.value)}
              disabled={isProcessing}
              minLength={10}
              maxLength={10}
              className={clsx("form-input", { error: errors?.phone })}
            />
            {errors?.phone && (
              <small className="form-error">{errors?.phone}</small>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-field">
            <label>Signature</label>
            <div className="form-signature">
              <SignatureCanvas
                ref={signatureRef}
                backgroundColor="white"
                onEnd={onSign}
                canvasProps={{
                  disabled: true,
                  style: {
                    pointerEvents: isProcessing && "none",
                    width: "100%",
                    height: "100%",
                    border: "1px solid #000",
                    borderRadius: "4px",
                    borderColor: isProcessing
                      ? "hsl(0, 0%, 90%)"
                      : errors?.signature
                      ? "#ff3548"
                      : "rgb(204, 204, 204)",
                  },
                }}
              />
            </div>
            {errors?.signature && (
              <small className="form-error">{errors?.signature}</small>
            )}
          </div>
          <div className="form-field form-field-filler" />
        </div>
        <div className="form-field">
          <Checkbox
            label={
              <>
                Je confirme avoir pris connaissance que la{" "}
                <a
                  href="https://www.francetravail.fr/employeur/solution-recrutement/2024/poei-une-formation-sur-mesure-av.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  POE
                </a>{" "}
                est une convention quadripartite qui engage France Travail (le
                financeur), le candidat, l’organisme de formation (dispensateur
                de la formation) et l’entreprise en vue de l’embauche d’un
                salarié. Elle permet de former un candidat en amont de son
                embauche pour lui permettre d’acquérir les compétences
                manquantes pour occuper le poste.
              </>
            }
            errored={!!errors?.rules_accepted}
            name="cobim"
            checked={values.rules_accepted}
            onChange={(event) => {
              setValues((rest) => ({
                ...rest,
                rules_accepted: event.target.checked,
              }));
            }}
            disabled={isProcessing}
          />
        </div>
        <div className="form-field">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={(response) => onChange("recaptcha", response)}
          />
          {errors?.recaptcha && (
            <small className="form-error">{errors?.recaptcha}</small>
          )}
        </div>
        {values?.success && (
          <div className="form-success">Attestation de soutien envoyée</div>
        )}
        <div className="form-date">Fait le {date}</div>
        <p
          className="description"
          style={{ marginTop: 10, textAlign: "center" }}
        >
          Merci pour votre participation !
        </p>
        <button type="submit" disabled={isProcessing} className="form-button">
          {isProcessing ? "Chargement" : "Envoyer"}
        </button>
        <hr style={{ borderColor: "#f5f5fb", marginTop: 20 }} />
        <p className="form-rgpd">
          Les données recueillies par ce formulaire ont pour unique but de mener
          l’étude d’identification des besoins en recrutement afin de vous
          recontacter, et ne seront pas réutilisées dans un autre cadre.
        </p>
        <div className="links">
          <a
            href="https://arinfo.fr/mentions-legales"
            target="_blank"
            rel="nopenner noreferrer"
          >
            Mentions légales
          </a>
          <a
            href="https://arinfo.fr/conditions-generales-de-prestation-et-de-vente"
            target="_blank"
            rel="nopenner noreferrer"
          >
            CGPV
          </a>
        </div>
      </form>
    </main>
  );
};

export default App;
